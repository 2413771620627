/* ********** Material Config ********** */

@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat-core();

$astrea-green: (
  50: #008F86, 100: #008F86, 200: #008F86, 300: #008F86, 400: #008F86, 500: #008F86, 600: #008F86, 700: #008F86, 800: #008F86, 900: #008F86, A100: #008F86, A200: #008F86, A400: #008F86, A700: #008F86,
  contrast: (
    50: #FAFAFA, 100: #FAFAFA, 200: #FAFAFA, 300: #FAFAFA, 400: #FAFAFA, 500: #FAFAFA, 600: #FAFAFA, 700: #FAFAFA, 800: #FAFAFA, 900: #FAFAFA, A100: #FAFAFA, A200: #FAFAFA, A400: #FAFAFA, A700: #FAFAFA,
  )
);

$astrea-primary: mat-palette($astrea-green);
$astrea-accent:  mat-palette($astrea-green);
$astrea-warn:    mat-palette($mat-red);
$astrea-theme:   mat-light-theme($astrea-primary, $astrea-accent, $astrea-warn);
@include all-component-colors($astrea-theme);

$typography: mat.define-typography-config(
    $font-family: var(--typography)
);
$typography-theme: mat.define-light-theme((
    typography: $typography
));
@include mat.all-component-themes($typography-theme);


/* ********** Styles ********** */

@import "./styles/astrea-styles.min";

html, body {
    height: 100%;
}
body {
    margin: 0;
}


// CLASES PARA TODA LA APP

.cdk-overlay-pane {
    max-width: 95vw !important;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
.o-ellipsis-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.gap-15 {
    gap: 15px;
}

// MAIN CONTAINER
.container {
    width: 80%;
    max-width: 1536px;
    margin: 0 auto;
}

@media screen and (max-width: 1440px) {
    .container {
        width: 100%;
    }
}

// PRODUCT PAGES
.products-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

@media screen and (max-width: 1024px) {
    .products-wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
}

@media screen and (max-width: 768px) {
    .products-wrapper {
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }
}

// GRIDS
.grid-25-75 {
    display: grid;
    grid-template-columns: 25% calc(75% - 25px);
    gap: 25px
}
.grid-33 {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 10px
}
.grid-40-60 {
    display: grid;
    grid-template-columns: calc(40% - 10px) 60%;
    gap: 10px
}
.grid-50-50 {
    display: grid;
    grid-template-columns: calc(50% - 25px) 50%;
    gap: 25px;
}
@media (max-width: 992px) {
    .grid-25-75 {
        grid-template-columns: 100%;
        gap: 15px
    }
    .grid-33 {
        grid-template-columns: 100%;
    }
    .grid-40-60 {
        grid-template-columns: 100%;
        gap: 5px
    }
    .grid-50-50 {
        grid-template-columns: 100%;
        gap: 15px;
    }
}

// ESTILOS GENERAL PARA TODOS LOS BOX
.astrea-box {
    background-color: var(--background-white-dark-1);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 1.3rem;
}
@media (max-width: 960px) {
    .astrea-box {
        overflow-x: scroll;
    }
}
@media (max-width: 576px) {
    .astrea-box {
        padding: 1rem;
    }
}

.astrea-without-stock {
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    color: var(--color-black-1);
    background-color: var(--color-white-3);
    border-radius: 50px;
    padding: 5px 15px;
    z-index: 1;
    left: 0;
    top: 0;
}

// DROPDOWN DATE PICKER
.astrea-date-box {
    cursor: pointer;
    background: var(--color-white-1);
    margin-top: 11px;
    height: 64px;

    small {
        color: var(--color-black-1);
    }

    .from-to {
        small {
            font-size: 15px;
        }
    }
}
.astrea-date-box:hover {
    border-bottom: 2px solid var(--color-green-1);
}
.astrea-date-dropdown {
    background: #FAFAFA !important;
    padding: 10px !important;
    padding-bottom: 0 !important;
    border-radius: 5px;
    width: 200px;
    box-shadow: 0 3px 6px #00000029;

    p {
        color: #121212;
        font-size: 16px !important;
        margin-bottom: 10px !important;
    }
    mat-form-field {
        .mat-form-field-wrapper {
            margin-bottom: 0 !important;
        }
        .mat-form-field-flex {
            background: #E4E6EB;
        }
    }
}

.astrea-table {
    color: var(--color-black-1);
    text-align: left;
    width: 100%;

    tr {
        height: 100%;

        &:nth-child(odd) {
            td {
                background-color: rgb(35, 187, 173, .1);
            }
        }
        th {
            color: var(--color-white-1);
            background-color: var(--color-green-3);
            font-size: 16px;
            font-weight: 700;
            padding: .8rem 1rem .8rem 1rem;
        }
    }
    tr td {
        padding: .8rem 1rem .8rem 1rem;
        font-size: 14px;
        font-weight: 500;
    }
    tr th, tr td {
        height: 100%;

        &:first-child {
            border-radius: var(--border-radius) 0px 0 var(--border-radius);
        }
        &:last-child {
            border-radius: 0px var(--border-radius) var(--border-radius) 0px;
            padding: 0 1rem;
        }

        .mat-icon-button {
            color: var(--color-green-1) !important;
            background: transparent;
            margin-right: .3rem;
            cursor: pointer;

            .material-icons {
                display: none;
                font-size: 26px;
            }
        }
    }
    tr:hover {
        .mat-icon-button {
            .material-icons {
                display: inline !important;
            }
        }
        .mat-icon-button:hover {
            color: var(--color-green-2) !important;
        }
    }
    @media (max-width: 576px) {
        tr {
            .mat-icon-button {
                .material-icons {
                    display: inline !important;
                }
            }
        }
    }
}
